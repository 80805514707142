import { merge } from '@shared/item-v0.1.0'
import type { NoodlNode } from '@shared/node-v1.0.0'
import type { Item } from '@shared/types-v0.1.0'
import type Surreal from 'surrealdb'
import type { Props } from '../definition'

export const initSurrealDb = async (p: Props, noodlNode: NoodlNode) => {
	const { namespace = 'default', database = 'data', customSurrealDb, surrealDbHost, surrealDbPort } = p
	const { project, environment = 'd3' } = Noodl.getProjectSettings()

	if (!project || !environment) {
		log.error('SurrealDb init: empty required props', { project, environment })
		return
	}

	const surrealDbModule = await import('surrealdb')
	const { Surreal, r, surql } = surrealDbModule

	const host = customSurrealDb ? surrealDbHost || 'localhost' : `surrealdb.${project}.${environment}.rolder.app`
	const port = customSurrealDb ? surrealDbPort || 8000 : 443

	R.surreal = {
		namespace,
		database,
		setRitems,
		r,
		//@ts-ignore
		surql,
		getRid,
		db: await getDb('https', Surreal, namespace, database, host, port),
		liveDb: await getDb('wss', Surreal, namespace, database, host, port),
	}
}

async function getDb(type: 'https' | 'wss', Surreal: any, namespace: string, database: string, host: string, port: number) {
	const st = log.start()

	const db = new Surreal() as Surreal
	const auth = { username: 'root', password: '#vJIfEGV2$quJE2^u$bW!vBf' }

	try {
		await db.connect(`${type}://${host}:${port}`, { namespace, database, auth })
		log.end(`Surrealdb init ${type}`, st)
		return db
	} catch (err) {
		log.error(`Failed to init SurrealDB (${type}):`, err instanceof Error ? err.message : String(err))
		await db.close()
		throw err
	}
}

const setRitems = (rawItems: ({ id: { id: string; tb: string } } & Record<string, unknown>)[]) => {
	return rawItems.map((rawItem) => {
		const item = getItem(rawItem)
		const proxyItem = R.items[item.id]

		if (proxyItem) merge({ object: item, proxyObject: proxyItem })
		else R.items[item.id] = item

		return R.items[item.id]
	})
}

export type SetRitems = typeof setRitems

const getItem = (rawItem: { id: { id: string; tb: string } } & Record<string, unknown>) => {
	const { id, ...item } = rawItem

	item.id = id.id
	item.table = id.tb
	// Обратная совместимость
	item.dbClass = id.tb.replaceAll('_', '-')

	const prototype = Object.create(
		{
			getRef: (dbClass: string) => {
				const globalItem = R.items[id.id]
				if (globalItem?.[dbClass]) {
					if (Array.isArray(globalItem[dbClass])) return globalItem[dbClass].map((i) => R.items[i.id]).filter((i) => !!i)
					return R.items[globalItem[dbClass].id]
				}
				return undefined
			},
			getBackRef: (dbClass: string) => {
				let resultRefItem: Item | undefined
				R.libs.just.map(R.items as any, (_, refItem: any) => {
					if (refItem.dbClass === dbClass && refItem[item.dbClass as string]?.id === id.id) resultRefItem = refItem
				})
				return resultRefItem
			},
			getDbClassState: (statePath: string) => R.libs.just.get(R.dbClasses, `${item.dbClass}.states.${statePath}`),
		},
		{
			id: { enumerable: true, writable: false, configurable: false, value: id.id },
			dbClass: { enumerable: true, writable: false, configurable: false, value: item.dbClass },
		}
	)

	return Object.assign(prototype, R.libs.just.omit(item, ['id', 'dbClass']))
}

export type GetRid = typeof getRid

const getRid = (table: string, id: string) => {
	const rid = `${table}:\`${id}\``
	return R.surreal.r`${rid}`
}
