import { jsNode } from '@shared/node-v1.0.0'

import v010 from '@nodes/query-db-v0.1.0'

export default jsNode(
	'queryDb',
	{
		'v0.1.0': v010,
	},
	{ docs: '' }
)
