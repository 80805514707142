import type { Uuid } from '@nodes/app-v2.0.0'
import type { BaseProps, Props } from '../node/definition'

export default (p: Props) =>
	({
		inited: false,
		query: p.query,
		liveTables: p.liveTables,
		subscribes: new Map(),
	}) satisfies Store

export type Store = BaseProps & {
	inited: boolean
	subscribes: Subscribes
}

export type QueryScheme = {
	query: string
	props: Record<string, unknown>
}

type Subscribes = Map<string, Uuid> // table: liveUuid
